import { useContext, useEffect, useState } from 'react'
import AuthContext from '../../globalState/context/AuthProvider';
import {AssService} from '../../services/AssService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


const ZigbeeChannelList = ({gatewayId}) => {
    
    const context = useContext(AuthContext)
    const [channelList,setChannelList] = useState([])
    const [receivedMsg,setReceivedMsg] = useState([])
    const [scanState,setScanState] = useState(false)
    const [duration,setDuration] = useState(6)
    const [nbCycles,setNbCycles] = useState(1)

    function onChannelListReceived(event,context){


        if(event.cmd == 'message' && event.args[2].startsWith('Energy'))
        {
          setReceivedMsg(event.args[2])
          AssService.terminateSavRequest(context)
          return true
        }
        
        return false
    }

    function getChannelList(type)
    {    
        setChannelList([]);
        setScanState(true)

        AssService.getObject(gatewayId).then((result)=>{
          AssService.sendSavRequest(result.user.resource.id,true).then((savId)=>{
            context.auth.socket.registerListener(result.realName,'message',onChannelListReceived,savId.resource.id)

              var commandArgs = [
                {"value":"command/io/ezsp/network","name":"command"},
                {"value":"dev-0/self","name":"arg1"},
                {"value":"radio_scan","name":"arg2"},
                {"value":duration,"name":"arg3"},
                {"value":nbCycles,"name":"arg4"}
                ];  
              AssService.sendGatewayCommand(result.user.resource.id,gatewayId,commandArgs)
          })
        })      
    }

    useEffect(()=>{
      console.debug(receivedMsg)
      if(receivedMsg != "")
      {
         var tmpStr = receivedMsg.split("\n")
         var routeArr = []
         console.debug(tmpStr)

         for (var i=4 ; i < tmpStr.length-1; i++ )
         {
            var classStr="row route"
            var tmpStr2 = tmpStr[i].split('|')

            if (tmpStr2[1].includes("best-channel"))
            {
              classStr += " best_channel"
            }
            else if (tmpStr2[1].includes("current-channel"))
            {
              classStr += " current_channel"
            }
            tmpStr2[1] = new DOMParser()
            .parseFromString(tmpStr2[1], "text/html")
            .documentElement.textContent;

            // | Channel | Min | Mean | Max | Channel1 | .... 
            var channelObj = {class:classStr,channelId:tmpStr2[1],min:tmpStr2[2],mean:tmpStr2[3],max:tmpStr2[4]};

            for (var j=1; j<=nbCycles; j++)
            {
              channelObj['cycle'+j] = tmpStr2[4+j]
            }

            routeArr.push(channelObj)
         }

         console.log(routeArr)
         setChannelList(routeArr)
         setReceivedMsg("")
         setScanState(false)
      }
    },[receivedMsg])


    return(<>
        <div class="row">
          <div class="col">
            <div>Temps d'écoute:</div>
            <RadioGroup
              row
              name="duration-group"
              value={duration}
              onChange={(event)=>{setDuration(event.target.value);console.log(event)}}
            >
              <FormControlLabel value="5" control={<Radio />} label="500ms" />
              <FormControlLabel value="6" control={<Radio />} label="1s" />
              <FormControlLabel value="7" control={<Radio />} label="1,5s" />
              <FormControlLabel value="8" control={<Radio />} label="2s" />
            </RadioGroup>
          </div>
          <div class="col">
            <div>Nombre de cycles:</div>
            <RadioGroup
              row
              name="cycles-group"
              value={nbCycles}
              onChange={(event)=>{setNbCycles(event.target.value)}}
            >
              <FormControlLabel value="1" control={<Radio />} label="1" />
              <FormControlLabel value="2" control={<Radio />} label="2" />
              <FormControlLabel value="3" control={<Radio />} label="3" />
              <FormControlLabel value="4" control={<Radio />} label="4" />
            </RadioGroup>
          </div>
        </div>
        <button className={scanState?'cartography_btn onAir':'cartography_btn'} onClick={getChannelList}>Lancer l'analyse</button>
        <div class="row cartography_header">
         <div class="col">
           <h6>Canal</h6>
         </div>
         <div class="col">
           <h6>Qualité min.</h6>
         </div>
         <div class="col">
           <h6>Qualité moy.</h6>
         </div>
         <div class="col">
           <h6>Qualité max.</h6>
         </div>
         <div class="col">
          <h6>Cycle 1</h6>
         </div>
         {(nbCycles > 1)&&
                  <div class="col">
                    <h6>Cycle 2</h6>
                 </div> 
         }
        {(nbCycles > 2)&&
                  <div class="col">
                    <h6>Cycle 3</h6>
                 </div> 
         }
        {(nbCycles > 3)&&
                  <div class="col">
                    <h6>Cycle 4</h6>
                 </div> 
         }
       </div>
       {channelList.map((data,index)=>{
        return(
          <>
            <div class={data.class}>
              <div class="col">
                {data.channelId}
              </div>
              <div class="col">
                {data.min}
              </div>
              <div class="col">
                {data.mean}
              </div>
              <div class="col">
                {data.max}
              </div>
              <div class="col">
                {data.cycle1}
              </div>
              {(nbCycles > 1)&&
                  <div class="col">
                    {data.cycle2}
                 </div> 
              }
              {(nbCycles > 2)&&
                  <div class="col">
                    {data.cycle3}
                 </div> 
              }
              {(nbCycles > 3)&&
                  <div class="col">
                    {data.cycle4}
                 </div> 
              }
            </div>
          </>
        );
       })}
   </>);
}
export default ZigbeeChannelList