import { useEffect, useMemo, useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import Select from 'react-select'

import dates from '../../utils/dates'
import Table from '../../components/table'

import {AssService} from '../../services/AssService'
import ObjectPopup from "./SingleObject"
import Popup from 'reactjs-popup'
import config from '../../config'

function UserObjects ({ i18n, t }) {

  const options = config.supported_objects();
  const [selectedFilter, setSelectedFilter] = useState([]);  
  const [searchPattern, setSearchPattern] = useState('')

  const [editPopupContent, setEditPopupContent] = useState({})
  const [editPopupState, setEditPopupState] = useState(false)
  const [confirmPopupState, setConfirmPopupState] = useState(false)
  const [idToDelete, setIdToDelete] = useState(0)
  const [otaStatus, setOtaStatus] = useState(false)
  const [otaUrl, setOtaUrl] = useState('')
  

  const queryClient = new QueryClient()
  const tableRef = useRef(null)

  useEffect(()=>{
    if(otaStatus)
    {
      var criteria = {ids:[editPopupContent.id]}
      AssService.sendOtaUpgradeRequest(otaUrl+"\u0000",criteria)
      setEditPopupState(false)
      setOtaStatus(false)
    }
  },[otaStatus])

  const columns = useMemo(() => [
    {
      id: 'state',
      accessorKey: 'connected',
      cell: props => (props.getValue() !== false ? <box-icon class='connection' color="#afe37b" type="solid" name="circle" /> : <box-icon class='connection' color="red" type="solid" name="circle" />),
      header: t('objects.table.header.connected')
    },
    {
      accessorKey: 'realName',
      header: t('objects.table.header.name')
    },
    {
      accessorKey: 'typeName',
      header: t('objects.table.header.type')
    },
    {
      accessorKey: 'firmware',
      header: t('objects.table.header.firmware')
    },
    {
      accessorKey: 'user',
      cell: (props) => (
        (typeof props.getValue() === 'object') ?
        <Link
          className='pointer'
          to={'/users/' + props.row.original.user.resource.id + '/profile'}>
          <span>{props.getValue().resource.login}</span>
        </Link> : 
        <span>No user linked</span>
      ),
      header: t('objects.table.header.user')
    },
    {
      accessorKey: 'delete',
      cell: (props) => (
        <div
          className='table-icon table-clickable'
          //onClick={() => onDelete(props.row.original.id)}>
          onClick={() => {setIdToDelete(props.row.original.id);setConfirmPopupState(true)}}>
          <box-icon type="solid" name="trash-alt"></box-icon>
          <span className="nav-text">{t('objects.table.delete')}</span>
        </div>
      ),
      header: t('objects.table.header.actions')
    },
    {
      accessorKey: 'open',
      cell: (props) => (
        <div
          className='pointer table-icon'
          onClick={() => {onEdit(props.row.original.id)}}>
          <span className="nav-text">{t('objects.table.details')}</span>
          <box-icon className='icon arrow' name='right-arrow-alt'></box-icon>
        </div>
      ),
      header: t('objects.table.header.actions')
    }
  ], [i18n, t])

  async function fetchObjects (parameters) {
    let query = {}

    if (parameters.pageFilter.trim().length > 0) {
      query['sift:name'] = parameters.pageFilter.trim()
    }

    if (selectedFilter.length > 0 ) {
      selectedFilter.forEach((filter) => {
        query['sift:type'] = query['sift:type']+','+filter.value
      })
    }

    (parameters.pageSortBy || []).forEach(sort => {
      query[`order:${sort.id}`] = sort.desc ? 'desc' : 'asc'
    })

    query['sift:ntype'] = "camera,log,STT,Email"
    query['page'] = parameters.pageIndex + 1
    query['size'] = parameters.pageSize

    try {
      return await AssService.getObjects(query)
    } catch (e) {
      throw new Error(`API error:${e?.message}`)
    }
  }

  const onSearchUpdate = (e) => {
    setSearchPattern(e.target.value)
  }

  function onEdit (id) {
    AssService.getObject(id)
			.then((response) => {
        console.log(response);
        setEditPopupContent(response)
        setEditPopupState(o => !o)
			})
			.catch((error) => {
				console.log(error);
			});
  }

  function onEditObject (properties) {
    console.log("Edit")
    setEditPopupState(false)
  }

  function onDelete(id) {
      try {
        id = idToDelete
        console.log(id)
        setIdToDelete(0)
        setConfirmPopupState(false)
        tableRef.current.reload()
        return AssService.deleteObject(id)
      } catch (e) {
        throw new Error(`API error:${e?.message}`)
      }    
  }

  function onUpgrade() {

  }

  return (
    <>
      <h1>{t('objects.title.main')}</h1>
      <div className='head-tools'>
        <input
          onChange={onSearchUpdate}
          placeholder={t('objects.search.placeholder')}
          type='text'
          value={searchPattern}/>
        <Select 
          className="filter"
          isMulti
          options={options.filter((option) => option.filter===1)} 
          onChange={(e) => {setSelectedFilter(e)}}
          />
      </div>
      <Popup open={confirmPopupState} closeOnDocumentClick modal className='confirmPopUp'>
        <h5>{t('objects.deleting.title')}</h5>
        <p>{t('objects.deleting.text')}</p>
        <button className="button" onClick={()=>{setIdToDelete(0);setConfirmPopupState(false)}}>{t('objects.deleting.cancel')}</button>
        <button className="button" onClick={onDelete}>{t('objects.deleting.validate')}</button>
      </Popup>
      <ObjectPopup
        callback={onEditObject}
        control={editPopupState}
        values={editPopupContent}
        t={t}
        otaAction={setOtaStatus}
        setOtaUrl={setOtaUrl}
        otaUrl={otaUrl} />
      <div>
        <QueryClientProvider client={queryClient}>
          <Table
            ref={tableRef}
            columns={columns}
            feeder={fetchObjects}
            keyword={searchPattern}
            externalTrigger={selectedFilter}
          />
        </QueryClientProvider>
      </div>
    </>
  )
}

export default withTranslation('ui')(UserObjects)
