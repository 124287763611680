const config = {
    /*--
    --*/
    'server' : () => {
        var currentServer = window.location.hostname;
        return currentServer;
    },
    'version' : "1.12.2",
    'ota_server' : () => {
        switch (config.server()) {
            case "preprod.calysphome.com":
            case "ma.calypshome.com":
            case "profalux.avidsen.one":
                return "profalux.avidsen.com"
            default:
                return "donkey.athemium.com"
        } 
    },

    /**
    * Array of all supported object by AvisenOne servers,
    * Can be filter by server
    * @param {filter} properties : Can appear in filter list
    * @param {add} properties : Can appear in add object list
    * @param {ota} properties : Ota type -> 0 - No ota / 1 - MQTT/Gateway ota / 2 - zigbee ota
    */
    'objects' : [
        /*"default" : {filter: 0, add: 0, ota: 0, tab_details:[{"name":"userName","editable":1,"pretreatment":"none"},{"name":"realName","editable":0,"pretreatment":"none"}],tab_parameters:[],tab_history:[]},*/
        { label: "VDoorBell", value: "VDoorBell", realName: 'access/vdp/', filter: 1, add: 1, ota: "http", servers: ['localhost','umii.avidsen.one','donkey.athemium.com'] },
        { label: "BoardGate", value: "BoardGate",  realName: 'gate/', parameters : ["mac_address"], filter: 1, add: 0, ota : "http", servers: ['localhost','umii.avidsen.one','donkey.athemium.com'] },
        { label: "AtHomeVDP", value: "AtHomeVDP", realName: 'access/vdp/', filter: 1, add: 1, ota : "http", servers: ['localhost','umii.avidsen.one','donkey.athemium.com','localhost','enki.avidsen.one','philips.avidsen.one'] },
        { label: "PhilipsVDP", value: "PhilipsVDP", realName: 'access/vdp/', filter: 1, add: 1, ota : "http", servers: ['localhost','umii.avidsen.one','donkey.athemium.com','localhost','enki.avidsen.one','philips.avidsen.one'] },
        { label: "Rolling_Shutter_Ezsp", value: "Rolling_Shutter_Ezsp", realName: '', filter: 1, add: 1, ota : "zigbee", servers: ['profalux.avidsen.one','preprod.calypshome.com','ma.calypshome.com','localhost'] },
        { label: "Venetian_Shutter_Ezsp", value: "Venetian_Shutter_Ezsp", realName: '', filter: 1, add: 1, ota : "zigbee", servers: ['profalux.avidsen.one','preprod.calypshome.com','ma.calypshome.com','localhost'] },
        { label: "EzspProbe", value: "EzspProbe", realName: '', filter: 1, add: 1, ota : "none", servers: ['profalux.avidsen.one','preprod.calypshome.com','ma.calypshome.com','localhost'] },
        { label: "Shade_Ezsp", value: "Shade_Ezsp", realName: '', filter: 1, add: 1, ota : "zigbee", servers: ['profalux.avidsen.one','preprod.calypshome.com','ma.calypshome.com','localhost'] },
        { label: "EzspThermostat", value: "EzspThermostat", realName: '', filter: 1, add: 1, ota : "zigbee", servers: ['preprod-enki.avidsen.one','enki.avidsen.one','donkey.athemium.com','localhost'] },
        { label: "RemoteProbe", value: "RemoteProbe", realName: '', filter: 1, add: 1, ota : "zigbee", servers: ['profalux.avidsen.one','preprod.calypshome.com','ma.calypshome.com','localhost'] },
        { label: "SwitchEzsp", value: "SwitchEzsp", realName: '', filter: 1, add: 1, ota : "zigbee", servers: ['profalux.avidsen.one','preprod.calypshome.com','ma.calypshome.com','localhost'] },
        { label: "TEST", value: "TEST", realName: 'access/vdp/', filter: 1, add: 1, ota : "http", servers: [''] },
    ],    
    /**
    * List of supported objects by a specific server'
    *
    */
    'supported_objects' : () => {
        console.log(config.server())
        return config.objects.filter((element) => element.servers.includes(config.server())) //TODO supprimer la liste des serveurs
    },
    /*--
    // Liste des commandes executables par une gateway
    --*/
    'gateway_commands' : [
        { "name": "command/cgi/restart <name>", "label": "command/cgi/restart", "description": "Restart a CGI program", "parameters": [{ "name": "name", "description": "The name of the program to restart" }] },
        { "name": "command/command/list", "label": "command/command/list", "description": "List all available applications", "parameters": [] },
        { "name": "command/command/man <application>", "label": "command/command/man", "description": "Get help for an application", "parameters": [{ "name": "application", "description": "Application name" }] },
        { "name": "command/command/manual [<format>]", "label": "command/command/manual", "description": "Get full manual", "parameters": [{ "name": "format", "description": "Format Defaults to 'html'." }] },
        { "name": "command/connector/websocket/get", "label": "command/connector/websocket/get", "description": "Get websocket statistics", "parameters": [] },
        { "name": "command/email/send <from> <to> <subjet> <message> [<from_name>] [<to_name>]", "label": "command/email/send", "description": "Send an email", "parameters": [{ "name": "from", "description": "Sender email" }, { "name": "to", "description": "Recipient email" }, { "name": "subjet", "description": "Subject" }, { "name": "message", "description": "Message of the email" }, { "name": "from_name", "description": "Presented sender Defaults to '(null)'." }, { "name": "to_name", "description": "Presented recipient Defaults to '(null)'." }] },
        { "name": "command/event/manager/send <eventname> <eventvalue> [<type>] [<timestamp>] [<dest>]", "label": "command/event/manager/send", "description": "Send an event", "parameters": [{ "name": "eventname", "description": "Name of the event." }, { "name": "eventvalue", "description": "Value of the event." }, { "name": "type", "description": "Type of the event. Defaults to 'event'." }, { "name": "timestamp", "description": "Timestamp of the event, in seconds. Defaults to '0'." }, { "name": "dest", "description": "Destination of the event. Defaults to ''." }] },
        { "name": "command/event/scheduler/add <name> <year> <month> <day> <hour> [<minute>] [<seconds>] [<repeat>]", "label": "command/event/scheduler/add", "description": "Add a scheduled event.", "parameters": [{ "name": "name", "description": "Event name" }, { "name": "year", "description": "Year (-1 for current)" }, { "name": "month", "description": "Month (-1 for current)" }, { "name": "day", "description": "Day (-1 for current)" }, { "name": "hour", "description": "Hour (-1 for current)" }, { "name": "minute", "description": "Minute (-1 for current) Defaults to '0'." }, { "name": "seconds", "description": "Seconds (-1 for current) Defaults to '0'." }, { "name": "repeat", "description": "Repeat value: none, second, minute, hour, day, week, month, year. Defaults to 'none'." }] },
        { "name": "command/input/file/csv/watch <filename>", "label": "command/input/file/csv/watch", "description": "Use a growing csv file as input. Format of events is then 'timestamp,attribute,value'. Format of commands is then 'timestamp,command,command line'", "parameters": [{ "name": "filename", "description": "File to open" }] },
        { "name": "command/io/ezsp/action <device> <value>", "label": "command/io/ezsp/action", "description": "Send a simple order to a plug, a shutter, a binary switch", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "value", "description": "The order which can be: 'on', 'off', 'toggle', 'open', 'close', 'up', 'down', 'stop', or some RGB representation ('#RRGGBB')." }] },
        { "name": "command/io/ezsp/color <device> <color>", "label": "command/io/ezsp/color", "description": "Update the color of a light", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "color", "description": "The RGB representation ('#RRGGBB') to apply" }] },
        { "name": "command/io/ezsp/discover <device> [<request>]", "label": "command/io/ezsp/discover", "description": "Start network discovery once again (current objects are maintained)", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "request", "description": "start|refresh|complete|stop. Defaults to 'start'." }] },
        { "name": "command/io/ezsp/dump <device> <object> [<output>]", "label": "command/io/ezsp/dump", "description": "Dump internal status information in log or as a message", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "object", "description": "The content to output, which can be: 'database', 'routes', 'config'." }, { "name": "output", "description": "Either a format ('json') or a verbosity level. In the latter case, the greater, the more information, in the range [0, 2]. Defaults to '1'." }] },
        { "name": "command/io/ezsp/get <device> <attributes> [<occurrences>]", "label": "command/io/ezsp/get", "description": "Read a specific attribute value on the device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "attributes", "description": "Either some aspect (routes, summary, limits, order_history, error_history, setting_history) or a list of attributes with the following format: <cluster_id>[<manufacturer_id>]:<attr_id>[,<attr_id>...]" }, { "name": "occurrences", "description": "For histories, a comma-separated list of occurrences to retrieve Defaults to '1'." }] },
        { "name": "command/io/ezsp/get_settings <device> <aspect>", "label": "command/io/ezsp/get_settings", "description": "Get plugin settings, like the registered reportings", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "aspect", "description": "The settings to retrieve: devices, reporting." }] },
        { "name": "command/io/ezsp/identify <device> <time>", "label": "command/io/ezsp/identify", "description": "Ask an object on the network to identify itself", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "time", "description": "How much seconds the node must identify itself, in the interval [5, 60]." }] },
        { "name": "command/io/ezsp/mfglib <device> <action> [<arg1>] [<arg2>] [<arg3>]", "label": "command/io/ezsp/mfglib", "description": "[DANGEROUS] Operate lowlevel on the ZigBee network", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "action", "description": "Only 'tone' is supported" }, { "name": "arg1", "description": "tone: channel (11-26) Defaults to ' '." }, { "name": "arg2", "description": "tone: power (Max 10) Defaults to ' '." }, { "name": "arg3", "description": "tone: duration (ms) Defaults to ' '." }] },
        { "name": "command/io/ezsp/network <device> <action> [<arg1>] [<arg2>]", "label": "command/io/ezsp/network", "description": "Operate on the ZigBee network", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "action", "description": "Either 'channel', 'check', 'create', 'create_commissioning', 'dump', 'join', 'leave', 'map', 'radio_scan', 'scan', 'try_check' or 'try_join'. The latter ones return an error if the dongle is already part of a network." }, { "name": "arg1", "description": "On create, this can be the requested channel. On scan this is the scan duration in the range [0, 14] and defaults to 6 (around 1 second per channel). On check or join action this is the extended PAN ID of the network to join, and by default the first open network is joined. Defaults to ' '." }, { "name": "arg2", "description": "On create, this is the centralised boolean. On radio scan this is the number of cycle and defaults to 1. On join this is the radio output power in the range [1, 8] (dBm) and defaults to 3. Defaults to ' '." }] },
        { "name": "command/io/ezsp/ota-bootload <device> <action> [<arg1>]", "label": "command/io/ezsp/ota-bootload", "description": "Operate on the ZigBee network", "parameters": [{ "name": "device", "description": "The ID of the target object using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "action", "description": "'abort', 'check_version', 'download', 'notify'." }, { "name": "arg1", "description": "If action is 'download', the target URL Defaults to ' '." }] },
        { "name": "command/io/ezsp/permit_join <device> [<timer>]", "label": "command/io/ezsp/permit_join", "description": "Open the ZigBee network to include a new device", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "timer", "description": "How much minutes, in the interval [0, 5], before the network is closed again. Defaults to '5'." }] },
        { "name": "command/io/ezsp/raw_send <node> <endpoint> <cluster> <bytes>", "label": "command/io/ezsp/raw_send", "description": "Send some arbitrary command towards a device", "parameters": [{ "name": "node", "description": "The target node ID." }, { "name": "endpoint", "description": "The target endpoint number." }, { "name": "cluster", "description": "The ID of the cluster which should handle the command." }, { "name": "bytes", "description": "The representation of the bytes to put in the ZCL frame." }] },
        { "name": "command/io/ezsp/refresh <device>", "label": "command/io/ezsp/refresh", "description": "Send again the statuses about the given device.", "parameters": [{ "name": "device", "description": "The target device" }] },
        { "name": "command/io/ezsp/rename <device> <name>", "label": "command/io/ezsp/rename", "description": "Store a user name in the object", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "name", "description": "The new name to store." }] },
        { "name": "command/io/ezsp/reset <device>", "label": "command/io/ezsp/reset", "description": "Send a EZ-Mode Invoke factory reset to the target. However, if the target is the controller itself, then properly leave the current network as soon as possible, with notifications to the user using LEDs.", "parameters": [{ "name": "device", "description": "The target device" }] },
        { "name": "command/io/ezsp/set <device> <attribute> <value>", "label": "command/io/ezsp/set", "description": "Set a specific attribute value on the device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "attribute", "description": "The name of the attribute to overwrite." }, { "name": "value", "description": "The new value to apply." }] },
        { "name": "command/io/ezsp/set_level <device> <level>", "label": "command/io/ezsp/set_level", "description": "Set the level or intensity of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "level", "description": "The position between 0 and 100, or the name of some defined level (FAVORITE, HEMSTITCHED)." }] },
        { "name": "command/io/ezsp/set_level_and_tilt <device> [<lift>] [<tilt>]", "label": "command/io/ezsp/set_level_and_tilt", "description": "Set the memorised position of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "lift", "description": "The lift position between 0 and 100 to override the one defined in position, or 0xFF. Defaults to '255'." }, { "name": "tilt", "description": "The angular position between 0 and 90 to override the one defined in position, or 0xFF. Defaults to '255'." }] },
        { "name": "command/io/ezsp/set_position <device> <position>", "label": "command/io/ezsp/set_position", "description": "Set the memorised position of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "position", "description": "The name of some defined position (FAVORITE, HEMSTITCHED)." }] },
        { "name": "command/io/ezsp/set_settings <device> <aspect> <value>", "label": "command/io/ezsp/set_settings", "description": "Set specific device or network settings", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "aspect", "description": "The settings to update. Currently, 'binding', 'commissioning', 'groups', and 'reporting' are implemented." }, { "name": "value", "description": "The values or the commands as a serialized JSON object or array. The format depends on the aspect." }] },
        { "name": "command/io/ezsp/set_tilt <device> <angle>", "label": "command/io/ezsp/set_tilt", "description": "Set the angular position of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "angle", "description": "The position between 0 and 90, or the name of some defined level (FAVORITE, HEMSTITCHED)." }] },
        { "name": "command/io/ezsp/store_position <device> <position> <level> <tilt>", "label": "command/io/ezsp/store_position", "description": "Define some position of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "position", "description": "The name of some defined position (FAVORITE, HEMSTITCHED)." }, { "name": "level", "description": "The position between 0 and 100, or nothing to store the current position." }, { "name": "tilt", "description": "The angular position between 0 and 90, or nothing to store the current position." }] },
        { "name": "command/log/configure_domains <line>", "label": "command/log/configure_domains", "description": "Set log level limits on domains", "parameters": [{ "name": "line", "description": "The limits definition." }] },
        { "name": "command/log/critical <formatstring>", "label": "command/log/critical", "description": "Log a critical trace", "parameters": [{ "name": "formatstring", "description": "String to log" }] },
        { "name": "command/log/debug <formatstring>", "label": "command/log/debug", "description": "Log a debug trace", "parameters": [{ "name": "formatstring", "description": "String to log" }] },
        { "name": "command/log/file/get [<offset>] [<length>]", "label": "command/log/file/get", "description": "Retrieve a portion of the log file. Result is sent as event event/log/file/content", "parameters": [{ "name": "offset", "description": "Start position. Use a negative value to get end of file. Defaults to '0'." }, { "name": "length", "description": "Length of the file to retrieve. Maximum value depends on the underlying protocol. Avoid to get more than 100KB at a time. Defaults to '10000'." }] },
        { "name": "command/log/message <formatstring>", "label": "command/log/message", "description": "Log a message trace", "parameters": [{ "name": "formatstring", "description": "String to log" }] },
        { "name": "command/log/set_level [<level>]", "label": "command/log/set_level", "description": "Sets debug level. Level is restored to a default value at startup", "parameters": [{ "name": "level", "description": "Log level to set : 0 (none), 1 (critical), 2 (warning), 3 (message), 4 (info) 5 (debug), 6 (heavy debug) Defaults to '0'." }] },
        { "name": "command/network/download <url> <filename>", "label": "command/network/download", "description": "Fetch a file", "parameters": [{ "name": "url", "description": "The location of the file" }, { "name": "filename", "description": "where the fetched file is to be saved" }] },
        { "name": "command/network/getpublicip", "label": "command/network/getpublicip", "description": "Get public IP", "parameters": [] },
        { "name": "command/object/rename <id> <name>", "label": "command/object/rename", "description": "Rename an object", "parameters": [{ "name": "id", "description": "The object unique ID" }, { "name": "name", "description": "The new name to use" }] },
        { "name": "command/system/base/backup", "label": "command/system/base/backup", "description": "Do a configuration backup", "parameters": [] },
        { "name": "command/system/base/exec <cmdline>", "label": "command/system/base/exec", "description": "Execute a system command asynchronously", "parameters": [{ "name": "cmdline", "description": "Command line to execute" }] },
        { "name": "command/system/base/halt", "label": "command/system/base/halt", "description": "Halt the system", "parameters": [] },
        { "name": "command/system/base/reboot", "label": "command/system/base/reboot", "description": "Reboot the system", "parameters": [] },
        { "name": "command/system/base/restore <type> <file>", "label": "command/system/base/restore", "description": "Restore a configuration backup", "parameters": [{ "name": "type", "description": "Type of backup to restore." }, { "name": "file", "description": "Inline backup file to restore ; encoded as @base64" }] },
        { "name": "command/system/base/settime <time>", "label": "command/system/base/settime", "description": "Set the system time", "parameters": [{ "name": "time", "description": "Time to use in seconds since the Epoch" }] },
        { "name": "command/system/config/debug-log", "label": "command/system/config/debug-log", "description": "Output the configuration file in the logs.", "parameters": [] },
        { "name": "command/system/config/get [<group>] <key> [<value>]", "label": "command/system/config/get", "description": "", "parameters": [{ "name": "group", "description": "Group. Empty to get all keys. Defaults to ''." }, { "name": "key", "description": "Key. " }, { "name": "value", "description": "Value to use if the key is not set. Defaults to ''." }] },
        { "name": "command/system/config/set <group> <key> <value>", "label": "command/system/config/set", "description": "", "parameters": [{ "name": "group", "description": "Group." }, { "name": "key", "description": "Key." }, { "name": "value", "description": "Value to associate with the key." }] },
        { "name": "command/system/gateway/os_update", "label": "command/system/gateway/os_update", "description": "Update the Domus Gateway Operating System", "parameters": [] },
        { "name": "command/system/gateway/restart", "label": "command/system/gateway/restart", "description": "Restart the Domus Gateway system", "parameters": [] },
        { "name": "command/system/gateway/update [<url>]", "label": "command/system/gateway/update", "description": "Update the Domus Gateway.", "parameters": [{ "name": "url", "description": "url of the update file Defaults to ''." }] },
        { "name": "command/ui/web/get", "label": "command/ui/web/get", "description": "Get Web server statistics", "parameters": [] },
    ]
};

/*
        { "name": "command/cgi/restart <name>", "label": "command/cgi/restart", "description": "Restart a CGI program", "parameters": [{ "name": "name", "description": "The name of the program to restart" }] },
        { "name": "command/command/list", "label": "command/command/list", "description": "List all available applications", "parameters": [] },
        { "name": "command/command/man <application>", "label": "command/command/man", "description": "Get help for an application", "parameters": [{ "name": "application", "description": "Application name" }] },
        { "name": "command/command/manual [<format>]", "label": "command/command/manual", "description": "Get full manual", "parameters": [{ "name": "format", "description": "Format Defaults to 'html'." }] },
        { "name": "command/connector/websocket/get", "label": "command/connector/websocket/get", "description": "Get websocket statistics", "parameters": [] },
        { "name": "command/email/send <from> <to> <subjet> <message> [<from_name>] [<to_name>]", "label": "command/email/send", "description": "Send an email", "parameters": [{ "name": "from", "description": "Sender email" }, { "name": "to", "description": "Recipient email" }, { "name": "subjet", "description": "Subject" }, { "name": "message", "description": "Message of the email" }, { "name": "from_name", "description": "Presented sender Defaults to '(null)'." }, { "name": "to_name", "description": "Presented recipient Defaults to '(null)'." }] },
        { "name": "command/event/manager/send <eventname> <eventvalue> [<type>] [<timestamp>] [<dest>]", "label": "command/event/manager/send", "description": "Send an event", "parameters": [{ "name": "eventname", "description": "Name of the event." }, { "name": "eventvalue", "description": "Value of the event." }, { "name": "type", "description": "Type of the event. Defaults to 'event'." }, { "name": "timestamp", "description": "Timestamp of the event, in seconds. Defaults to '0'." }, { "name": "dest", "description": "Destination of the event. Defaults to ''." }] },
        { "name": "command/event/scheduler/add <name> <year> <month> <day> <hour> [<minute>] [<seconds>] [<repeat>]", "label": "command/event/scheduler/add", "description": "Add a scheduled event.", "parameters": [{ "name": "name", "description": "Event name" }, { "name": "year", "description": "Year (-1 for current)" }, { "name": "month", "description": "Month (-1 for current)" }, { "name": "day", "description": "Day (-1 for current)" }, { "name": "hour", "description": "Hour (-1 for current)" }, { "name": "minute", "description": "Minute (-1 for current) Defaults to '0'." }, { "name": "seconds", "description": "Seconds (-1 for current) Defaults to '0'." }, { "name": "repeat", "description": "Repeat value: none, second, minute, hour, day, week, month, year. Defaults to 'none'." }] },
        { "name": "command/input/file/csv/watch <filename>", "label": "command/input/file/csv/watch", "description": "Use a growing csv file as input. Format of events is then 'timestamp,attribute,value'. Format of commands is then 'timestamp,command,command line'", "parameters": [{ "name": "filename", "description": "File to open" }] },
        { "name": "command/io/ezsp/action <device> <value>", "label": "command/io/ezsp/action", "description": "Send a simple order to a plug, a shutter, a binary switch", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "value", "description": "The order which can be: 'on', 'off', 'toggle', 'open', 'close', 'up', 'down', 'stop', or some RGB representation ('#RRGGBB')." }] },
        { "name": "command/io/ezsp/color <device> <color>", "label": "command/io/ezsp/color", "description": "Update the color of a light", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "color", "description": "The RGB representation ('#RRGGBB') to apply" }] },
        { "name": "command/io/ezsp/discover <device> [<request>]", "label": "command/io/ezsp/discover", "description": "Start network discovery once again (current objects are maintained)", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "request", "description": "start|refresh|complete|stop. Defaults to 'start'." }] },
        { "name": "command/io/ezsp/dump <device> <object> [<output>]", "label": "command/io/ezsp/dump", "description": "Dump internal status information in log or as a message", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "object", "description": "The content to output, which can be: 'database', 'routes', 'config'." }, { "name": "output", "description": "Either a format ('json') or a verbosity level. In the latter case, the greater, the more information, in the range [0, 2]. Defaults to '1'." }] },
        { "name": "command/io/ezsp/get <device> <attributes> [<occurrences>]", "label": "command/io/ezsp/get", "description": "Read a specific attribute value on the device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "attributes", "description": "Either some aspect (routes, summary, limits, order_history, error_history, setting_history) or a list of attributes with the following format: <cluster_id>[<manufacturer_id>]:<attr_id>[,<attr_id>...]" }, { "name": "occurrences", "description": "For histories, a comma-separated list of occurrences to retrieve Defaults to '1'." }] },
        { "name": "command/io/ezsp/get_settings <device> <aspect>", "label": "command/io/ezsp/get_settings", "description": "Get plugin settings, like the registered reportings", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "aspect", "description": "The settings to retrieve: devices, reporting." }] },
        { "name": "command/io/ezsp/identify <device> <time>", "label": "command/io/ezsp/identify", "description": "Ask an object on the network to identify itself", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "time", "description": "How much seconds the node must identify itself, in the interval [5, 60]." }] },
        { "name": "command/io/ezsp/mfglib <device> <action> [<arg1>] [<arg2>] [<arg3>]", "label": "command/io/ezsp/mfglib", "description": "[DANGEROUS] Operate lowlevel on the ZigBee network", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "action", "description": "Only 'tone' is supported" }, { "name": "arg1", "description": "tone: channel (11-26) Defaults to ' '." }, { "name": "arg2", "description": "tone: power (Max 10) Defaults to ' '." }, { "name": "arg3", "description": "tone: duration (ms) Defaults to ' '." }] },
        { "name": "command/io/ezsp/network <device> <action> [<arg1>] [<arg2>]", "label": "command/io/ezsp/network", "description": "Operate on the ZigBee network", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "action", "description": "Either 'channel', 'check', 'create', 'create_commissioning', 'dump', 'join', 'leave', 'map', 'radio_scan', 'scan', 'try_check' or 'try_join'. The latter ones return an error if the dongle is already part of a network." }, { "name": "arg1", "description": "On create, this can be the requested channel. On scan this is the scan duration in the range [0, 14] and defaults to 6 (around 1 second per channel). On check or join action this is the extended PAN ID of the network to join, and by default the first open network is joined. Defaults to ' '." }, { "name": "arg2", "description": "On create, this is the centralised boolean. On radio scan this is the number of cycle and defaults to 1. On join this is the radio output power in the range [1, 8] (dBm) and defaults to 3. Defaults to ' '." }] },
        { "name": "command/io/ezsp/ota-bootload <device> <action> [<arg1>]", "label": "command/io/ezsp/ota-bootload", "description": "Operate on the ZigBee network", "parameters": [{ "name": "device", "description": "The ID of the target object using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "action", "description": "'abort', 'check_version', 'download', 'notify'." }, { "name": "arg1", "description": "If action is 'download', the target URL Defaults to ' '." }] },
        { "name": "command/io/ezsp/permit_join <device> [<timer>]", "label": "command/io/ezsp/permit_join", "description": "Open the ZigBee network to include a new device", "parameters": [{ "name": "device", "description": "The ID of the controller dev-<home>/self." }, { "name": "timer", "description": "How much minutes, in the interval [0, 5], before the network is closed again. Defaults to '5'." }] },
        { "name": "command/io/ezsp/raw_send <node> <endpoint> <cluster> <bytes>", "label": "command/io/ezsp/raw_send", "description": "Send some arbitrary command towards a device", "parameters": [{ "name": "node", "description": "The target node ID." }, { "name": "endpoint", "description": "The target endpoint number." }, { "name": "cluster", "description": "The ID of the cluster which should handle the command." }, { "name": "bytes", "description": "The representation of the bytes to put in the ZCL frame." }] },
        { "name": "command/io/ezsp/refresh <device>", "label": "command/io/ezsp/refresh", "description": "Send again the statuses about the given device.", "parameters": [{ "name": "device", "description": "The target device" }] },
        { "name": "command/io/ezsp/rename <device> <name>", "label": "command/io/ezsp/rename", "description": "Store a user name in the object", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "name", "description": "The new name to store." }] },
        { "name": "command/io/ezsp/reset <device>", "label": "command/io/ezsp/reset", "description": "Send a EZ-Mode Invoke factory reset to the target. However, if the target is the controller itself, then properly leave the current network as soon as possible, with notifications to the user using LEDs.", "parameters": [{ "name": "device", "description": "The target device" }] },
        { "name": "command/io/ezsp/set <device> <attribute> <value>", "label": "command/io/ezsp/set", "description": "Set a specific attribute value on the device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "attribute", "description": "The name of the attribute to overwrite." }, { "name": "value", "description": "The new value to apply." }] },
        { "name": "command/io/ezsp/set_level <device> <level>", "label": "command/io/ezsp/set_level", "description": "Set the level or intensity of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "level", "description": "The position between 0 and 100, or the name of some defined level (FAVORITE, HEMSTITCHED)." }] },
        { "name": "command/io/ezsp/set_level_and_tilt <device> [<lift>] [<tilt>]", "label": "command/io/ezsp/set_level_and_tilt", "description": "Set the memorised position of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "lift", "description": "The lift position between 0 and 100 to override the one defined in position, or 0xFF. Defaults to '255'." }, { "name": "tilt", "description": "The angular position between 0 and 90 to override the one defined in position, or 0xFF. Defaults to '255'." }] },
        { "name": "command/io/ezsp/set_position <device> <position>", "label": "command/io/ezsp/set_position", "description": "Set the memorised position of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "position", "description": "The name of some defined position (FAVORITE, HEMSTITCHED)." }] },
        { "name": "command/io/ezsp/set_settings <device> <aspect> <value>", "label": "command/io/ezsp/set_settings", "description": "Set specific device or network settings", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "aspect", "description": "The settings to update. Currently, 'binding', 'commissioning', 'groups', and 'reporting' are implemented." }, { "name": "value", "description": "The values or the commands as a serialized JSON object or array. The format depends on the aspect." }] },
        { "name": "command/io/ezsp/set_tilt <device> <angle>", "label": "command/io/ezsp/set_tilt", "description": "Set the angular position of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "angle", "description": "The position between 0 and 90, or the name of some defined level (FAVORITE, HEMSTITCHED)." }] },
        { "name": "command/io/ezsp/store_position <device> <position> <level> <tilt>", "label": "command/io/ezsp/store_position", "description": "Define some position of a device", "parameters": [{ "name": "device", "description": "The complete ID of the node using format dev-<home>/IEEEAddr-<addr>:cluster-<fn>:endpoint-in_<n>." }, { "name": "position", "description": "The name of some defined position (FAVORITE, HEMSTITCHED)." }, { "name": "level", "description": "The position between 0 and 100, or nothing to store the current position." }, { "name": "tilt", "description": "The angular position between 0 and 90, or nothing to store the current position." }] },
        { "name": "command/log/configure_domains <line>", "label": "command/log/configure_domains", "description": "Set log level limits on domains", "parameters": [{ "name": "line", "description": "The limits definition." }] },
        { "name": "command/log/critical <formatstring>", "label": "command/log/critical", "description": "Log a critical trace", "parameters": [{ "name": "formatstring", "description": "String to log" }] },
        { "name": "command/log/debug <formatstring>", "label": "command/log/debug", "description": "Log a debug trace", "parameters": [{ "name": "formatstring", "description": "String to log" }] },
        { "name": "command/log/file/get [<offset>] [<length>]", "label": "command/log/file/get", "description": "Retrieve a portion of the log file. Result is sent as event event/log/file/content", "parameters": [{ "name": "offset", "description": "Start position. Use a negative value to get end of file. Defaults to '0'." }, { "name": "length", "description": "Length of the file to retrieve. Maximum value depends on the underlying protocol. Avoid to get more than 100KB at a time. Defaults to '10000'." }] },
        { "name": "command/log/message <formatstring>", "label": "command/log/message", "description": "Log a message trace", "parameters": [{ "name": "formatstring", "description": "String to log" }] },
        { "name": "command/log/set_level [<level>]", "label": "command/log/set_level", "description": "Sets debug level. Level is restored to a default value at startup", "parameters": [{ "name": "level", "description": "Log level to set : 0 (none), 1 (critical), 2 (warning), 3 (message), 4 (info) 5 (debug), 6 (heavy debug) Defaults to '0'." }] },
        { "name": "command/network/download <url> <filename>", "label": "command/network/download", "description": "Fetch a file", "parameters": [{ "name": "url", "description": "The location of the file" }, { "name": "filename", "description": "where the fetched file is to be saved" }] },
        { "name": "command/network/getpublicip", "label": "command/network/getpublicip", "description": "Get public IP", "parameters": [] },
        { "name": "command/object/rename <id> <name>", "label": "command/object/rename", "description": "Rename an object", "parameters": [{ "name": "id", "description": "The object unique ID" }, { "name": "name", "description": "The new name to use" }] },
        { "name": "command/system/base/backup", "label": "command/system/base/backup", "description": "Do a configuration backup", "parameters": [] },
        { "name": "command/system/base/exec <cmdline>", "label": "command/system/base/exec", "description": "Execute a system command asynchronously", "parameters": [{ "name": "cmdline", "description": "Command line to execute" }] },
        { "name": "command/system/base/halt", "label": "command/system/base/halt", "description": "Halt the system", "parameters": [] },
        { "name": "command/system/base/reboot", "label": "command/system/base/reboot", "description": "Reboot the system", "parameters": [] },
        { "name": "command/system/base/restore <type> <file>", "label": "command/system/base/restore", "description": "Restore a configuration backup", "parameters": [{ "name": "type", "description": "Type of backup to restore." }, { "name": "file", "description": "Inline backup file to restore ; encoded as @base64" }] },
        { "name": "command/system/base/settime <time>", "label": "command/system/base/settime", "description": "Set the system time", "parameters": [{ "name": "time", "description": "Time to use in seconds since the Epoch" }] },
        { "name": "command/system/config/debug-log", "label": "command/system/config/debug-log", "description": "Output the configuration file in the logs.", "parameters": [] },
        { "name": "command/system/config/get [<group>] <key> [<value>]", "label": "command/system/config/get", "description": "", "parameters": [{ "name": "group", "description": "Group. Empty to get all keys. Defaults to ''." }, { "name": "key", "description": "Key. " }, { "name": "value", "description": "Value to use if the key is not set. Defaults to ''." }] },
        { "name": "command/system/config/set <group> <key> <value>", "label": "command/system/config/set", "description": "", "parameters": [{ "name": "group", "description": "Group." }, { "name": "key", "description": "Key." }, { "name": "value", "description": "Value to associate with the key." }] },
        { "name": "command/system/gateway/os_update", "label": "command/system/gateway/os_update", "description": "Update the Domus Gateway Operating System", "parameters": [] },
        { "name": "command/system/gateway/restart", "label": "command/system/gateway/restart", "description": "Restart the Domus Gateway system", "parameters": [] },
        { "name": "command/system/gateway/update [<url>]", "label": "command/system/gateway/update", "description": "Update the Domus Gateway.", "parameters": [{ "name": "url", "description": "url of the update file Defaults to ''." }] },
        { "name": "command/ui/web/get", "label": "command/ui/web/get", "description": "Get Web server statistics", "parameters": [] }
*/

export default config;