import { useEffect, useMemo, useState, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { withTranslation } from 'react-i18next'


import UserNav from './UserNav'
import ObjectPopup from '../objects/SingleObject'
import Table from '../../components/table'

import dates from '../../utils/dates'
import AuthContext from '../../globalState/context/AuthProvider';

import {AssService} from '../../services/AssService'
//import Popup from 'reactjs-popup'
//import Select from 'react-select'
import config from '../../config'
//import { Visibility } from '@tanstack/react-table'
import AddCircleIcon from '@mui/icons-material/AddCircle';
//import DeleteIcon from '@mui/icons-material/Delete';
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Gateway from '../../components/Gateway/Gateway'
import DeletePopUp from '../../components/popups/DeletePopUp'
import AddGatewayPopUp from '../../components/popups/AddGatewayPopUp'
import AddObjectPopUp from '../../components/popups/AddObjectPopUp'

function UserPage ({ i18n, t }) {
  const { userId } = useParams()
  const context = useContext(AuthContext)
  const [editPopupContent, setEditPopupContent] = useState({})
  const [editPopupState, setEditPopupState] = useState(false)
  
  const [addGatewayPopupState, setAddGatewayPopupState] = useState(false)
  const [addObjectPopupState, setAddObjectPopupState] = useState(false)
  const [dummyState, setDummyState] = useState(0);

  const [confirmPopupState, setConfirmPopupState] = useState(false)
  const [idToDelete, setIdToDelete] = useState(0)

  const [otaStatus, setOtaStatus] = useState(false)
  const [otaUrl, setOtaUrl] = useState('')
  const [connectedObject,setConnectedObject] = useState(0)


  const [gatewaysList, setGatewaysList] = useState([])
  const [gatewayCorrespondancy, setGatewayCorrespondancy] = useState(new Map())
  const [fwVersion, setFwVersion] = useState(new Map())

  const queryClient = new QueryClient()
  const tableRef = useRef(null)


  useEffect(()=>{
    if(otaStatus)
    {
      console.log("OTA request")
      var criteria = {ids:[editPopupContent.id]}
      AssService.sendOtaUpgradeRequest(otaUrl,criteria)
      setEditPopupState(false)
      setOtaStatus(false)
    }
  },[otaStatus])

  useEffect(()=>{
    console.log(gatewayCorrespondancy)
  },[gatewayCorrespondancy])

  useEffect(()=>{
    let query = {}
    query['sift:userId'] = userId
    AssService.getGateways(query).then((result)=>{
      result.forEach((gateway) => {
        setGatewayCorrespondancy(new Map(gatewayCorrespondancy.set(gateway.gateway.resource.id,gateway.id)))
      })
      setGatewaysList(result);
    })

    let query2 = {}
    query2['sift:userId'] = userId
    query2['sift:ntype'] = "camera,log,gateway,Mobile,Scheduler,associations,Composite,Weather,WeeklyPlanner,Connectors,Zigbee_EZSP"

    AssService.getObjectsWithAllFilter(query2).then(result2 =>{
      setConnectedObject(result2.connectedCount);
    })
    .catch(e => {
      throw new Error(`API error:${e?.message}`)
    });

  },[dummyState])

  useEffect(()=>{
    console.log(idToDelete)
  },[idToDelete])

  function onMsgReceived(event,context) {
    console.log(event)
    if(event.cmd == 'status' && event.args[1].endsWith("/version"))
    {
      console.log(event.args[2])
      setFwVersion(new Map(fwVersion.set(context.id,event.args[2])))
      //setReceivedMsg(event.args[2])
      //setScanState(false)
      AssService.terminateSavRequest(context.savId)
      return true
    }
    
    return false
  }

  function registerFwValue(id,value,type) {

    console.log(type)
    if (value || fwVersion.has(id))
    {      
      if(!fwVersion.has(id))
      {
        setFwVersion(new Map(fwVersion.set(id,value)))
        setDummyState(Date.now());
      }
      return true
    } else if (config.objects.filter((object) => object.value === type).length > 0) {
      if (config.objects.find((object) => object.value === type).ota === "zigbee")
      {
        return false
      }
    } 

    setFwVersion(new Map(fwVersion.set(id,"---")))
    return true
  }

  const columns = useMemo(() => [
    {
      accessorKey: 'id',
      header: '#'
    },
    {
      accessorKey: 'connected',
      cell: props => (props.getValue() !== false ? <box-icon class='connection' color="#afe37b" type="solid" name="circle" /> : <box-icon class='connection' color="red" type="solid" name="circle" />),
      header: t('objects.table.header.connected')
    },/*
    {
      accessorKey: 'realName',
      header: t('objects.table.header.name')
    },*/
    {
      accessorKey: 'userName',
      header: t('objects.details.user_name')
    },
    {
      accessorKey: 'typeName',
      header: t('objects.table.header.type')
    },
    {
      accessorKey: 'gateway',
      cell: props => (props.getValue() != null ? props.getValue().resource.name : t('objects.table.no_gateway')),
      header: t('objects.table.header.gateway')
    },
    {
      accessorKey: 'firmware',
      header: t('objects.table.header.firmware'),
      cell: props => (
          registerFwValue(props.row.original.id,props.getValue(),props.row.original.typeName) ? fwVersion.get(props.row.original.id) : <button className='floating-button' onClick={(evt)=>{
          console.log(evt)
          AssService.sendSavRequest(userId,true).then(sav=>{
            context.auth.socket.registerListener(props.row.original.gateway.resource.name,'status',onMsgReceived,{savId:sav.resource.id,id:props.row.original.id})
              var commandArgs = [
                {"value":"command/io/ezsp/ota-bootload","name":"command"},
                {"value":props.row.original.realName,"name":"arg1"},
                {"value":"check_version","name":"arg2"}            
              ];  
              AssService.sendGatewayCommand(userId,gatewayCorrespondancy.get(props.row.original.gateway.resource.id),commandArgs)
          });
        }}>Récupérer la version</button> )
    },
    {
      accessorKey: 'lastEvent',
      cell: props => (props.getValue() != null ? dates.format(i18n, props.getValue()) : t('objects.table.no_date')),
      header: t('objects.table.header.last_event')
    },
    {
      accessorKey: 'delete',
      cell: (props) => (
        <div
          className='table-icon table-clickable'
          onClick={() => {console.log(props.row.original.id);setIdToDelete(props.row.original.id);setConfirmPopupState(true)}}>
          <box-icon type="solid" name="trash-alt"></box-icon>
          <span class="nav-text">{t('objects.table.delete')}</span>
        </div>
      ),
      header: t('objects.table.header.actions')
    },
    {
      cell: (props) => (
        <div
          className='pointer table-icon'
          onClick={() => {onEdit(props.row.original.id)}}>
          <span class="nav-text">{t('objects.table.details')}</span>
          <box-icon class='icon arrow' name='plus-circle'></box-icon>
        </div>
      ),
      header: t('objects.table.header.actions')
    }
  ], [i18n, t, fwVersion])

  async function fetchObjects (parameters) {
    let query = {}; /* semi-colon is important because of the parenthesis below. */

    (parameters.pageSortBy || []).forEach(sort => {
      query[`order:${sort.id}`] = sort.desc ? 'desc' : 'asc'
    })

    query['sift:userId'] = userId
    query['sift:ntype'] = "camera,log,STT,Email"

    query['page'] = parameters.pageIndex + 1
    query['size'] = parameters.pageSize

    try {
      var result = await AssService.getObjects(query);
      /*
      result.data.forEach((object)=>{
        console.log(gatewayCorrespondancy)
        if(object.gateway)
          object.gatewayObject = gatewayCorrespondancy.get(object.gateway.resource.id)
        
      })*/
      return result//.then((datas) => {console.log("nb equipements:"+datas.count)})
    } catch (e) {
      throw new Error(`API error:${e?.message}`)
    }
  }

  function onEdit (id) {
    AssService.getObject(id)
                 .then((response) => {
                   response.gatewayObject = gatewayCorrespondancy.get(response.gateway.resource.id)
                   console.log(response)
                   setEditPopupContent(response)
                   setEditPopupState(o => !o)
                 })
                 .catch((error) => {
                   console.log(error)
                 })
  }

  function onEditObject (properties) {
    console.log("Edit")
    setEditPopupState(false)
  }

  function onDelete() {
    setIdToDelete(0);
    setDummyState(Date.now());
    tableRef.current.reload()
  }

  function onPopupExit() {
    setDummyState(Date.now());
    setAddObjectPopupState(false);
    setAddGatewayPopupState(false);
  }

  return (
    <>
      <UserNav title={t('users.objects.title')} />
      <DeletePopUp action={[idToDelete,onDelete]} t={t} />
      <AddGatewayPopUp action={[addGatewayPopupState,userId,onPopupExit]} t={t} />
      <AddObjectPopUp action={[addObjectPopupState,userId,onPopupExit]} t={t} />
      <h5 className='floating-button'>{t('objects.title.gateways')}</h5>
      <button className='floating-button' onClick={()=>{setAddGatewayPopupState(true)}}>
        <AddCircleIcon/>{t('objects.add.button')}
      </button>
      <div className="floating-cancel"></div>
      <div className='tableGatewayProfile'>
        {gatewaysList?.map((gateway,index)=>{
          return(
            <>
              <Gateway key={index} gateway={gateway} t={t} i18n={i18n} action={onPopupExit}></Gateway>
            </>
          )
        })}
      </div>
      <h5 className='floating-button'>{t('objects.title.objects')} - {connectedObject} connectés</h5>
      <button className='floating-button' onClick={()=>{setAddObjectPopupState(true)}}>
        <AddCircleIcon/>{t('objects.add.button')}
      </button>
      <div className='tableObjectProfile'>
        <QueryClientProvider client={queryClient}>
          <Table
            ref={tableRef}
            columns={columns}
            feeder={fetchObjects}
            externalTrigger={dummyState} />
        </QueryClientProvider>
      </div>
      <ObjectPopup
        callback={onEditObject}
        control={editPopupState}
        values={editPopupContent}
        t={t}
        otaAction={setOtaStatus}
        setOtaUrl={setOtaUrl}
        otaUrl={otaUrl} />
    </>
  )
}

export default withTranslation('ui')(UserPage)