import { useContext, useEffect, useState } from 'react'
import AuthContext from '../../globalState/context/AuthProvider';
import {AssService} from '../../services/AssService';

const Cartography = ({gatewayId}) => {

    const context = useContext(AuthContext)
    const [networkList,setNetworkList] = useState([])
    const [receivedMsg,setReceivedMsg] = useState([])
    const [cartoState,setCartoState] = useState(false)
    const [realName,setRealName] = useState('')
    //const [ezspVersion,setEzspVersion] = useState(0)
    

    function onNetworkListReceived(event,context){

      console.log(event)
        if (event.args[1].startsWith("event/system/config/atm_io_ezsp"))
        {
            setReceivedMsg([...receivedMsg,event])
        }
        else if (event.args[1].startsWith("transient/event/io/ezsp/dev-0/self/discovery"))
        {
          console.log(event.args[2])
          if (event.args[2] == "completed" || event.args[2] == "" )
          {
            setCartoState(false)
            AssService.terminateSavRequest(context)

            return true
          }
          /*
          else if (event.args[2] == "starting")
          {
            setEzspVersion(1)
          }*/
          else
          {
            console.log("cartography on going ...")
            setCartoState(true)
          }
        }
        
        return false
    }

    function getNetworkList(type)
    {
      
      var commandArgs = [
        {"value":"command/io/ezsp/discover","name":"command"},
        {"value":"dev-0/self","name":"arg1"},
        {"value":type,"name":"arg2"}];      

        setReceivedMsg([]);
        setNetworkList([]);

        AssService.getObject(gatewayId).then((result)=>{
          AssService.sendSavRequest(result.user.resource.id,true).then((savId)=>{
            console.log(result)
            setRealName(result.realName)
            context.auth.socket.registerListener(result.realName,'status',onNetworkListReceived,savId.resource.id)
            AssService.sendGatewayCommand(result.user.resource.id,gatewayId,commandArgs)
          }).catch((error)=>{
            alert("Impossible d'executer une cartographie. Veuillez reessayer ultérieurment")
          })
        })      
    }

    function onStop()
    {
      AssService.getObject(gatewayId).then((result)=>{  
        AssService.getSavRequestsStatus({'sift:userId': result.user.resource.id,'size':50}).then(savList => {
          var savId = 0
          console.log(savList)
          savList.data.forEach(element => {
            if (element.state != "TERMINATED")
            {
              console.log(element.state)
              savId = element.id
            }
          });

          if ( savId === 0) // Aucune opération en cours
          {
            AssService.sendSavRequest(result.user.resource.id).then(sav =>{
              //context.auth.socket.registerListener(result.realName,'status',onNetworkListReceived,sav.resource.id)
              stopCarto(result,sav.resource.id)
            }).catch(err => {
              console.error(err)
            })
          }
          else // une opération en cours
          {
            stopCarto(result,savId)
            //TODO supprimer la CB de la ws 
          }

        })
      })
    }

    function stopCarto(object,savId)
    {
      
      var commandArgs = [
        {"value":"command/io/ezsp/discover","name":"command"},
        {"value":"dev-0/self","name":"arg1"},
        {"value":"stop","name":"arg2"}];  

        setRealName(object.realName)
        
        AssService.sendGatewayCommand(object.user.resource.id,gatewayId,commandArgs).then(result =>{
          AssService.terminateSavRequest(savId)
        })

        setCartoState(false)
        //context.auth.socket.unregisterListener(realName,'status',onNetworkListReceived)
        //TODO: unregister callback
      
    }

    useEffect(()=>{
      if(receivedMsg.length > 0)
      {
          var variables = receivedMsg[0].args[1].split('/');
          var addr = variables[4];
          var info = variables[5];
          
          var i = networkList.slice(0).findIndex(element => {return element.key == addr})
          if( i > -1)
          {
            var newArray = networkList[i].infos

            if ( newArray.findIndex(element => {return element.name == info}) === -1)
            {
              newArray.push({name:info,value:receivedMsg[0].args[2]})
              networkList.splice(i,1,{key:addr,infos:newArray})

              setNetworkList(
                networkList
              )
            }
          } 
          else
          {
            setNetworkList([
              ...networkList,
              { key: addr, infos: [{name:info,value:receivedMsg[0].args[2]}] }]
            )
          }

          receivedMsg.shift()
          setReceivedMsg(receivedMsg)
      }
    },[receivedMsg])

    return(<>
        <button className={cartoState?'cartography_btn onAir':'cartography_btn'} onClick={() => {if(!cartoState)getNetworkList("start")}}>Cartographie</button>
        <button className={cartoState?'cartography_btn onAir':'cartography_btn'} onClick={() => {if(!cartoState)getNetworkList("complete")}}>Cartographie Complete</button>
        <button className={cartoState?'cartography_btn onAir':'cartography_btn'} onClick={() => {if(!cartoState)getNetworkList("refresh")}}>Rafraichir</button>
        <button className='cartography_btn' onClick={onStop}>Stop</button>
        <div class="row cartography_header">
         <div class="col-1">
             <h6>Id noeud</h6>
         </div>
         <div class="col-3">
           <h6>Adresse IEEE</h6>
         </div>
         <div class="col-1">
           <h6>Type</h6>
         </div>
         <div class="col">
           <h6>Catégorie</h6>
         </div>
         <div class="col">
           <h6>Fabricant</h6>
         </div>
         <div class="col">
           <h6>Nom</h6>
         </div>
         <div class="col">
           <h6>Protocole</h6>
         </div>
       </div>
       {networkList.map((data,index)=>{
        return(
          <>
            <div class="row">
              <div class="col-1">
                { (data.infos.find(element => {return element.name == "node_id"})!= undefined)?data.infos.find(element => {return element.name == "node_id"}).value:"undefined" }
              </div>
              <div class="col-3">
                {data.key.split(':')[0]}
              </div>
              <div class="col-1">
                ---
              </div>
              <div class="col">
                {data.key.split(':')[1]}
              </div>
              <div class="col">
                { (data.infos.find(element => {return element.name == "manufacturer_name"})!= undefined)?data.infos.find(element => {return element.name == "manufacturer_name"}).value:"undefined" }
              </div>
              <div class="col">
                { (data.infos.find(element => {return element.name == "__user_name"})!= undefined)?data.infos.find(element => {return element.name == "__user_name"}).value:"undefined" }
              </div>
              <div class="col">
                { (data.infos.find(element => {return element.name == "protocol"})!= undefined)?data.infos.find(element => {return element.name == "protocol"}).value:"undefined" }
              </div>
            </div>
          </>
        );
       })}
   </>);
}

export default Cartography