import { useContext, useEffect, useState } from 'react'
import AuthContext from '../../globalState/context/AuthProvider';
import {AssService} from '../../services/AssService';

const ZigbeeRoutes = ({objectId,gatewayObjectId}) => {
    
    const context = useContext(AuthContext)
    const [routeList,setRouteList] = useState([])
    const [receivedMsg,setReceivedMsg] = useState("")
    const [scanState,setScanState] = useState(false)
     

    function onRouteListReceived(event,context){
        console.log(event)
        if(event.cmd == 'message' && event.args[2].startsWith("**Routes"))
        {
          setReceivedMsg(event.args[2])
          setScanState(false)
          AssService.terminateSavRequest(context)
          return true
        }
        
        return false
    }

    function getRouteList(type)
    {    
        setRouteList([]);
        setScanState(true)

        AssService.getObject(objectId).then((result)=>{

            AssService.sendSavRequest(result.user.resource.id,true).then((savId)=>{
              context.auth.socket.registerListener(result.gateway.resource.name,'message',onRouteListReceived,savId.resource.id)
              var commandArgs = [
                {"value":"command/io/ezsp/get","name":"command"},
                {"value":result.realName,"name":"arg1"},
                {"value":"routes","name":"arg2"}            
              ];  
              AssService.sendGatewayCommand(result.user.resource.id,gatewayObjectId,commandArgs)
            })
        })      
    }

    useEffect(()=>{
      if(receivedMsg != "")
      {
         var tmpStr = receivedMsg.split("\n")
         var routeArr = []

         for (var i=4 ; i < tmpStr.length-1; i++ )
         {
            var classStr="row route"
            var tmpStr2 = tmpStr[i].split('|')
            console.log(tmpStr2);
            if (tmpStr2[4].includes("good-route"))
            {
              tmpStr2[4] = new DOMParser()
              .parseFromString(tmpStr2[4], "text/html")
              .documentElement.textContent;

              classStr += " good_route"
            }
            else if (tmpStr2[4].includes("bad-route"))
            {
              tmpStr2[4] = new DOMParser()
              .parseFromString(tmpStr2[4], "text/html")
              .documentElement.textContent;

              classStr += " bad_route"
            }
            // | Name | Short ID | Best Route | Worst LQI | Worst section | 
            routeArr.push({class:classStr,name:tmpStr2[1],shortId:tmpStr2[2],bestRoute:tmpStr2[3],worstLQI:tmpStr2[4],worstSection:tmpStr2[5]})
         }

         setRouteList(routeArr)
         setReceivedMsg("")
      }
    },[receivedMsg])

    useEffect(()=>{
      console.log(routeList)
    },[routeList])


    return(<>
        <button className={scanState?'cartography_btn onAir':'cartography_btn'} onClick={getRouteList}>Routes</button>
        <div class="row cartography_header">
         <div class="col">
             <h6>Name</h6>
         </div>
         <div class="col-1">
           <h6>ShortID</h6>
         </div>
         <div class="col">
           <h6>Best route</h6>
         </div>
         <div class="col-1">
           <h6>Worst LQI</h6>
         </div>
         <div class="col">
           <h6>Worst section</h6>
         </div>
       </div> 
       {routeList.map((data,index)=>{
        return(
          <>
            <div class={data.class}>
              <div class="col">
                {data.name}
              </div>
              <div class="col-1">
                {data.shortId}
              </div>
              <div class="col">
                {data.bestRoute}
              </div>
              <div class="col-1">
                {data.worstLQI}
              </div>
              <div class="col">
                {data.worstSection}
              </div>
            </div>
          </>
        );
       })}
   </>);
}
export default ZigbeeRoutes