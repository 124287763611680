/**
 * CommandPopUp component renders a popup for sending gateway commands.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.action - The action array containing the following elements:
 *   [0]: {boolean} - Indicates whether the popup is open.
 *   [1]: {Object} - Contains gateway and resource information.
 *     gateway: {Object} - Gateway information.
 *     resource: {Object} - Resource information.
 *     id: {string} - gateway ID.
 *     realName: {string} - Real name of the gateway.
 *   [2]: {Function} - Callback function to be executed after the command is sent.
 * @param {Function} props.t - Translation function.
 *
 * @returns {JSX.Element} The CommandPopUp component.
 */
import Popup from 'reactjs-popup';
import config from '../../config'
import Select from 'react-select'
import { useState, useContext, useEffect } from 'react';
import { AssService } from '../../services/AssService';
import AuthContext from '../../globalState/context/AuthProvider';
import { use } from 'react';


const CommandPopUp = ({action,t}) => {
    const [command, setCommand] = useState('');
    const context = useContext(AuthContext)
    const [arg1, setArg1] = useState('');
    const [arg2, setArg2] = useState('');
    const [arg3, setArg3] = useState('');
    const [arg4, setArg4] = useState('');
    const [arg5, setArg5] = useState('');
    const [arg6, setArg6] = useState('');
    const [arg7, setArg7] = useState('');
    const [arg8, setArg8] = useState('');
    const args = [{parameter:arg1,function:setArg1}, {parameter:arg2,function:setArg2}, {parameter:arg3,function:setArg3}, {parameter:arg4,function:setArg4}, {parameter:arg5,function:setArg5}, {parameter:arg6,function:setArg6}, {parameter:arg7,function:setArg7}, {parameter:arg8,function:setArg8}];
    const [result, setResult] = useState('');
    const [savRequestId,setSavRequestId] = useState('');
    const [timer,setTimer] = useState(null);

    useEffect(() => {
        console.log(context.auth.csl);
        setResult(context.auth.csl);
    }, [context]);

    useEffect(() => {
        const timeoutId = setInterval(() => {
            console.log(context.auth.csl);
            setResult(context.auth.csl);
        }, 10000);
    
        return () => clearInterval(timeoutId);
      }, []);

    function onMessageReceived(event,csl){
        
        context.auth.csl = context.auth.csl + event.args[0] + ' | ' + event.args[1] + ' | ' + event.args[2] + '\n'
        console.log(context.auth.csl)
        return false

        /*
        if (event.cmd === 'message')
        {
            setResult(event.args[2])
            AssService.terminateSavRequest(context)
            setCommand(null)
            return true
        }*/
        
    }

    function onCommand()  {
        var _this = this;

        AssService.sendSavRequest(action[1].user.resource.id,true).then(savId => {
            console.log("Gateway command:"+command.label)
            setSavRequestId(savId.resource.id)  
            const commandArgs = [
                {"value":command.label,"name":"command"}]
                
            
            args.map((param, index) => {
                if (param.parameter === '')
                {
                    if (index <  command.parameters.length)
                        commandArgs.push({"value":"","name":"arg"+(index+1)})
                    else
                        return
                }

                commandArgs.push({"value":param.parameter,"name":"arg"+(index+1)})
            })

            //context.auth.socket.registerListener(action[1].realName,'message',onMessageReceived,savId.resource.id)
            context.auth.socket.registerListener(action[1].realName,()=>{return true},onMessageReceived,savId.resource.id)
            AssService.sendGatewayCommand(action[1].user.resource.id,action[1].id,commandArgs).then(()=>{
                setCommand(null)
                //AssService.terminateSavRequest(savId.resource.id)
                //action[2]()
            }).catch(err=>{
                context.auth.csl = ""
                console.log(err)    
                AssService.terminateSavRequest(savId.resource.id)
            })
        }).catch(err=>{
            alert('Veuillez réessayer ultérieurement')
        })
    }

    return (
        <Popup 
          open={action[0]}  
          className='commandPopUp'
          closeOnEscape={false}
          closeOnDocumentClick={false}>
            <h4>{t('users.gateways.command.title')}</h4>
            <label>{t('users.gateways.command.choose')}</label>
            <Select
                options={config.gateway_commands} 
                onChange={(e) => {console.log(e);setCommand(e)}}
            />
            {command&&<div className='commandDescription'>
                    <label>Description :&nbsp;</label>
                    {command.name}
                    {command.description}
                </div>}
            {command&&command.parameters.map((param, index) => {
                return(
                    <div className='commandParameter'>
                        <span>{param.description}</span>
                        <input
                            placeholder={param.name}
                            type='text'
                            value={args[index].parameter}
                            onChange={(e) =>(args[index].function)(e.target.value)}
                        />
                    </div>
                )
            } )}
            {(command == null)&&<div style={{ whiteSpace: 'pre-wrap', flex: '1 1 0%' }}>
                <textarea   value={result} readOnly={true} style={{ width: '100%', height: '400px', resize: 'none' }} />
            </div>}
            {command&&<button className="button" onClick={() => {onCommand()}}>Send</button>}
            <button className="button" onClick={() => {
                context.auth.csl =""
                if (savRequestId != '')
                {
                    AssService.terminateSavRequest(savRequestId).then(()=>{
                        //TODO : unregister callback
                        setSavRequestId('')
                        action[2]()
                    })
                }
                else
                {
                    action[2]()
                }
            }
            }>Close</button>
        </Popup>
    );
}
  
export default CommandPopUp;