import axios from 'axios'


function APIClient (baseURL) {
  this.client = axios.create({baseURL})

  this.client.interceptors.request.use(
    (config) => {
      config.headers.authorization = ''
      if ((baseURL !== '/services/dain' && config.url !== 'login') || config.method === 'get') {
        const token = localStorage.getItem('token')
        if (token && token !== 'undefined' && token !== null) {
          config.headers.authorization = `Bearer ${token}`
        }
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    })

  this.client.interceptors.response.use(
    // (response) => new Promise((resolve, reject) => {
    //   resolve(response)
    // }),
    (response) => response,
    (error) => {
      /* Dealing with user side errors */
      if (!error.response) {
        return Promise.reject(error)
      }

      /* Dealing with server side response errors */
      switch (error.response.data.error.status) {
        case 400:
          if (error.response.data.error.msgKey === 'invalid_token') {
            localStorage.removeItem('token')
            if (window.location.pathname !== '/')
              window.location = '/'
            else
              return Promise.reject(error)
          }
          break
        case 401:
          localStorage.removeItem('token')
          if (window.location.pathname !== '/')
            window.location = '/'
          else
            return Promise.reject(error)
          break
        case 403:
          window.location = '/unauthorized'
          break
        default:
          return Promise.reject(error)
      }
    })
}

APIClient.prototype.buildPath = function (segments, query) {
  let path = ''

  if (segments != null) {
    if (Array.isArray(segments))
      path = segments.join('/')
    else
      path = String(segments)
  }

  if (query != null)
    path += '?' + Object.entries(query).map(([key, value]) => {
      return (value === '')? key : key + '=' + value
    }).join('&')

  return path
}

APIClient.prototype.delete = async function (segments) {
  const path = this.buildPath(segments)

  const response = await this.client.delete(path)
  console.log('DELETE', [this.client.defaults.baseURL, path].join('/'), response)

  return response.data
}

APIClient.prototype.get = async function (segments, query) {
  const path = this.buildPath(segments, query)

  const response = await this.client.get(path)
  console.log('GET', [this.client.defaults.baseURL, path].join('/'), response)
  
	return response.data
}

APIClient.prototype.post = async function (segments, properties) {
  const path = this.buildPath(segments)
  console.log(path)
  console.log(properties)
  const response = await this.client.post(path, properties)
  console.log('POST', [this.client.defaults.baseURL, path].join('/'), response)
  return response.data
}

APIClient.prototype.put = async function (segments, properties) {
  const path = this.buildPath(segments)

  const response = await this.client.put(path, properties)
  console.log('PUT', [this.client.defaults.baseURL, path].join('/'), response)

  return response.data
}

function DainClient () {
  APIClient.call(this, '/services/dain')
}

DainClient.prototype = Object.create(APIClient.prototype)
DainClient.prototype.constructor = DainClient

function DurinClient () {
  APIClient.call(this, '/services/durin')
}

DurinClient.prototype = Object.create(APIClient.prototype)
DurinClient.prototype.constructor = DurinClient

DurinClient.prototype.get = async function (segments, query, normalizer) {

  const data = await(APIClient.prototype.get.call(this, segments, query))

  return this.parse(data, normalizer)
}

DurinClient.prototype.put = async function (segments, query, normalizer) {
  const data = await(APIClient.prototype.put.call(this, segments, query))
  return this.parse(data, normalizer)
}


DurinClient.prototype.parse = function (data, normalizer) {
  if (data['content'] != null) {
    return this.normalizeCollection(data, normalizer)
  } else if (data['resource'] != null) {
    if (normalizer !== undefined) {
      return normalizer(data.resource)
    } else {
      return data.resource
    }
  } else {
    throw new Error('Invalid response')
  }
}

DurinClient.prototype.normalizeCollection = function (data, itemNormalizer) {
  const parseUriQuery = function (uri) {
    if (uri != null) {
      return uri
        .substring(uri.indexOf('?') + 1)
        .split('&')
        .reduce((o, param) => {
          const values = param.split('=')
          Object.defineProperty(o, values[0], {
            enumerable: true,
            value: values[1],
            writable: false})

          return o
        }, {})
    } else {
      return null
    }
  }

  const nextPage = parseUriQuery(data.next)
  const previousPage = parseUriQuery(data.previous)

  let page = {page: 1, size: 10}

  if (nextPage != null)
    page = {page: nextPage.page - 1, size: nextPage.size}
  else if (previousPage != null)
    page = {page: previousPage.page + 1, size: previousPage.size}

  return {
    count: Math.floor(data.count / page.size) + 1,
    data:  data.content.reduce((r, value, index) => {
      if (itemNormalizer !== undefined)
        r.push(itemNormalizer(value.resource))
      else
        r.push(value.resource)
      return r
    }, []),
    next: nextPage,
    previous: previousPage,
    page: page,
    connectedCount: data?.connectedCount,
    uri: data.uri
	}
}


export { APIClient, DainClient, DurinClient }
